<template>
  <a-modal
    title="组织机构图"
    :width="1900"
    :keyboard="false"
    :maskClosable="false"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @cancel="handleCancel"
    :footer="null"
  >
    <a-spin :spinning="formLoading">
      <a-row>
        <a-col style="text-align: right;">
          <a-button-group>
            <a-button type="primary" :ghost="direction" @click="changeDirection(false)">纵向</a-button>
            <a-button type="primary" :ghost="!direction" @click="changeDirection(true)">横向</a-button>
          </a-button-group>
        </a-col>
      </a-row>
      <div>
        <vue2-org-tree :data="orgTree" :horizontal="direction" />
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import Vue2OrgTree from 'vue-tree-color'
export default {
  data() {
    return {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 5
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 15
        }
      },
      orgTree: {},
      visible: false,
      confirmLoading: false,
      formLoading: true,
      viewData: {
        id: 0,
        label: 'XXX科技有限公司',
        children: [
          {
            id: 2,
            label: '产品研发部',
            children: [
              {
                id: 5,
                label: '研发-前端'
              },
              {
                id: 6,
                label: '研发-后端'
              },
              {
                id: 9,
                label: 'UI设计'
              },
              {
                id: 10,
                label: '产品经理'
              }
            ]
          },
          {
            id: 3,
            label: '销售部',
            children: [
              {
                id: 7,
                label: '销售一部'
              },
              {
                id: 8,
                label: '销售二部'
              }
            ]
          },
          {
            id: 4,
            label: '财务部'
          },
          {
            id: 9,
            label: 'HR人事'
          }
        ]
      },
      direction: false
    }
  },
  created() {},
  components: {
    Vue2OrgTree
  },
  methods: {
    init(orgTree) {
      this.orgTree = orgTree[0]
      this.visible = true
      this.convertTitlesToLabels(this.orgTree)
      this.formLoading = false
    },
    convertTitlesToLabels(item) {
      // 如果当前项有title属性，将其改为label
      if (item.hasOwnProperty('title')) {
        item['label'] = item['title']
        delete item['title']
      }

      // 如果当前项有children数组，递归遍历并处理每个子项
      if (Array.isArray(item.children)) {
        item.children.forEach(child => {
          this.convertTitlesToLabels(child)
        })
      }
    },
    changeDirection(dit) {
      this.direction = dit
    },
    handleCancel() {
      this.direction = false
      this.visible = false
      this.formLoading = false
    }
  }
}
</script>
